.Products-items {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  grid-row-gap: 1.5em;
  display: grid;
}
.Products-item {
  text-decoration: none;
  box-shadow: 8px 14px 38px rgba(39,44,49,0.06), 1px 3px 8px rgba(39,44,49,0.03);
  border-radius: 5px;
  margin: 0 0 20px 0;
  position: relative;
}
.Products-item-info {
  padding: 10px;
}
.Products-item-info h2 {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.Products-item-info h2 span {
  color: #33b13a;
}
.Products-item-info p {
  font-size: 14px;
  font-weight: 300;
}
.Products-item img {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  object-fit: contain;
}
.Products-item button {
  background: linear-gradient(to bottom, #63b8ee 5%, #468ccf 100%);
  width: 100%;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  border: 0px;
  outline: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
}
.Products-item button:hover {
  background-color: rgba(39,44,49,0.06);
}
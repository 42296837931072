.Header {
  display: flex;
  justify-content: space-between;
}
.Header-checkout {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  width: 30px;
  margin: 0 0 0 0.5em;
}
.Header-alert {
  color: #33b13a;
  font-size: 14px;
  margin-left: 5px;
}
.Header a {
  text-decoration: none;
  color: #33b1ff;
}
